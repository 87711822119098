<template>
  <div class="home page">
    <div class="gradient"></div>
        <header>

            <div class="nav-container">
                <router-link to="/"><img src="@/assets/img/logo.png" alt="logo" class="logo"></router-link>
                <Nav />
            </div>
            <div>
              <!-- <div class="slideshow">
                <div class="slides">
                    <div class="slide">
                        <img src="@/assets/img/club_house.webp" alt="image" loading="lazy">
                    </div>
                </div>
            </div> -->
               <SlideshowAdv />
            </div>
        </header>
        <section class="content">

            <section>
                <div class="section-image-text section">
                    <img src="@/assets/img/club_house_mini.jpg" alt="clubhouse image" loading="lazy">
                    <div el="list xsm" style="padding-top:16px;">
                        <h4 el>Welcome to Alphaleaf Home Owners Association</h4>
                        <p el>
                            Alphaleaf HOA is a single family housing development located in Loudoun County, right up against the border between Loudoun and Fairfax county along Rt-50. The Home Owners' Association consists of 311 homes built by <a href="http://www.pulte.com/">Pulte Homes</a> and <a href="http://www.brookfieldhomes.com">Brookfield Homes</a>.
                        </p>
                        <p el>
                            If you are a resident of our community, <a href="/login" target="_top">click here</a> to log into the private side of the web site.
                        </p>
                        <div>
                          <!-- <router-link to="/" class="button">learn more</router-link> -->
                          <a href="https://www.fsresidential.com/washington-dc/pay-association-fees" class="button">Make a Payment</a>
                        </div>
                    </div>
                </div>

            </section>
            <section>

                <div class="section-events section" style="min-height:500px;">
                    <div el="list sm">
                        <h4 el>Community News</h4>
                        <p el>View community information here.</p>
                        <!-- <div>
                          <button>Get Involved</button>
                        </div> -->
                        <NewsList />
                    </div>
                </div>
            </section>
            <!-- <section>

                <div class="section-news section">
                    <div el="list sm">
                        <h4 el>News</h4>
                        <p el>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque laoreet dolor in venenatis blandit. Curabitur et ex nec dui dapibus efficitur vel eu ipsum. Maecenas dignissim velit mollis, accumsan neque eu, vulputate felis. Integer lobortis dapibus justo, et suscipit arcu. Praesent at suscipit justo. Phasellus aliquet efficitur elit, eget vulputate nisl. Nullam non dolor gravida, suscipit dui at, lacinia neque.</p>
                        <NewsList />
                        <div><button>View More</button></div>
                    </div>
                </div>
            </section> -->

        </section>
        <Footer></Footer>
  </div>

</template>

<script>
import Nav from '@/components/Nav.vue'
import SlideshowAdv from '@/components/SlideshowAdv.vue'
import NewsList from '@/components/NewsList.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Nav,
    SlideshowAdv,
    NewsList,
    Footer
  },
  data(){
    return {
      
      
    }
  },
  head: {
    title: {
      inner: 'Home'
    },
    meta: [
      { name: 'theme-color', content: '#283a5c' },
      { name: 'description', content: 'Welcome to Alphaleaf Home Owners Association.', id: 'desc' }, 
      {name:'og:site_name', content: 'Alphaleaf Home Owners Association'},
      {name:'og:title', content: 'Alphaleaf Home Owners Association'},
      {name:'og:description', content: "Alphaleaf HOA is a single family housing development located in Loudoun County, right up against the border between Loudoun and Fairfax county along Rt-50. The Home Owners' Association consists of 311 homes built by Pulte Homes and Brookfield Homes."},
      {name:'og:image', content: 'https://alphaleaf-clubhouse-test.web.app/assets/img/club_house.jpg'},
      {name:'og:url', content: 'https://alphaleaf-clubhouse-test.web.app/'},
      {name:'twitter:card', content: 'summary_large_image'},
    ],
    link: [
      { rel: 'canonical', href: 'https://alphaleaf-clubhouse-test.web.app/', id: 'canonical' },
    ],
  },
  computed:{
      slideshow(){
          return this.$store.state.slideshowData
      }
  },
}

// db.collection("playlists").add({
//       accountID: 'test',
//       playlistName: 'folderName',
//       timestamp: 'Date',
//       transitionType: ''
//   }).then(function(docRef) {
//       console.log("Document written with ID: ", docRef.id);
//   }).catch(function(error) {
//       alert("Error creating playlist: ", error);
//   });
</script>
<style lang="scss">
.slideshow{
    width:100vw;
}
.slideshow .slides{
    height:700px;
}
@media (max-width:1250px){
  .slideshow .slides{
      height:500px;
  }
}
@media (max-width:600px){
    .slideshow .slides {
        height: 350px !important;
    }
}
</style>