<template>
        <div class="newsList">
            <div v-for="item in filteredPosts" :key="item" class="news" @click="openPost(item.id)">
                <div class="newsDetails">
                    <b class="newsTitle" :title="item.title">{{item.title}}</b>
                    <p class="newsSummary" el>By: {{item.author.name}} | {{formatDate(item.updatedOn)}}</p>
                </div>
                <div>
                    <img :src="item.img" loading="lazy" alt="Article image" />
                </div>
            </div>
        </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'NewsList',
  //props: ['data'],
  data() {
    return {
        speed: 4, //in seconds
        autoplay: true,
        action: null,
        posts:{
            list:[],
            search:''
        }
    }
  },
  computed:{
    filteredPosts(){
        var x = this;
        let posts = _.orderBy(x.posts.list, ['createdOn'], ['desc']);
        return _.filter(posts, function(item) {
            return _.includes(_.lowerCase(item.title+' '+item.summary), x.posts.search);
        });
      }
    //   data(){
    //       return this.$store.state.newsData
    //   }
  },
  methods: {
    openLink: function(url) {
        window.location.href = url;
    },
    openPost(ID) {
        this.$router.push('/posts/'+ID);
    },
    getPosts(){
        var x = this;
        fetch('https://us-east4-alphaleaf-clubhouse-test.cloudfunctions.net/getPublicPosts')
        .then(response => response.json())
        .then(data => x.posts.list = data);
    },
    formatDate(date){
          let d = new Date(date);
          return d.toLocaleDateString('en-US');
      }
  },
    mounted: function() {
        this.getPosts();
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* .newsDetails{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.newsList {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 15px;
            margin: 1.5rem 0;
        }

        .news {
            display: flex;
            padding: 10px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 3px;
            justify-content: space-between;
            flex-direction: column-reverse;
            width: 100%;
            cursor: pointer;
            /* max-width:363px; */
        }

        .newsList>*:nth-child(1) {
            grid-column-end: span 2;
            /* max-width:741px; */
        }

        .news:hover {
            border: 1px solid var(--primary-color);
        }

        .news>*:first-child {
            margin-right: 10px;
        }

        .news img {
            width: 100%;
            height: 150px;
            margin-bottom: 10px;
            object-fit: cover;
            object-position: center;
            border-radius: 3px;
            display: block;
            background-color: rgba(0,0,0,0.05);
        }

        @media (max-width: 900px) {
            .newsList {
                grid-template-columns: 1fr 1fr;
            }

        }

        @media (max-width: 600px) {
            .newsList {
                grid-template-columns: 1fr;
            }

            .newsList>*:nth-child(1) {
                grid-column-end: span 1;
            }
        }

</style>
