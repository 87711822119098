// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
// import { GoogleAuthProvider } from "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import "firebase/performance";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  apiKey: "AIzaSyAxU3kTo0dsqcZ5J0mMR0ha-4MtS5SeuN4",
  authDomain: "alphaleaf-clubhouse-test.firebaseapp.com",
  projectId: "alphaleaf-clubhouse-test",
  storageBucket: "alphaleaf-clubhouse-test.appspot.com",
  messagingSenderId: "1022319931437",
  appId: "1:1022319931437:web:916a7efbdc37a6d4adc8a4"
};
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const provider = new firebase.auth.GoogleAuthProvider();
